<template>
  <div class="">
    <div>
      <div class="text">
        <div class="title">应用ID：</div>
        <div class="txt">
          <el-input v-model="param.app_id" style="width: 400px"></el-input>
        </div>
      </div>

      <div class="text">
        <div class="title">应用名称：</div>
        <div class="txt">
          <el-input style="width: 400px" v-model="param.app_name"></el-input>
        </div>
      </div>

      <div class="text">
        <div class="title">密钥：</div>
        <div class="txt">
          <el-input v-model="param.secret" style="width: 400px" disabled placeholder="（暂不支持录入，新增成功后生成）"></el-input>
        </div>
      </div>

      <div class="text">
        <div class="title">备注：</div>
        <div class="txt">
          <el-input v-model="param.note" style="width: 400px"></el-input>
        </div>
      </div>

      <div class="text">
        <el-button type="primary" @click="addApp" style="margin-left: 340px;"> 确认新增 </el-button>
        <el-button type="primary" @click="reset"> 重置 </el-button>
        <!-- <div style="flex: 1"></div> -->
      </div>
    </div>

    <el-dialog v-model="dialogVisible" title="新增成功，请牢记密钥信息！" width="600">
      <div class="dialog">
        <div class="dialog_text">
          <div class="dialog_title">应用ID：</div>
          <div class="dialog_txt">
            {{ ok_param.app_id }}
          </div>
        </div>
        <div class="dialog_text">
          <div class="dialog_title">应用名称：</div>
          <div class="dialog_txt">
            {{ ok_param.app_name }}
          </div>
        </div>
        <div class="dialog_text">
          <div class="dialog_title">密钥：</div>
          <div class="dialog_txt">
            {{ ok_param.secret }}
          </div>
        </div>
        <div style="text-align: left">
          <el-button type="primary" size="small" plain @click="copyButton('all')">
            复制全部
          </el-button>
          <el-button type="primary" size="small" plain @click="copyButton('secret')">
            复制密钥
          </el-button>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            关闭
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { add_app } from "@/api/bank/app.js";
import { ElMessage } from "element-plus";
import useClipboard from "vue-clipboard3";

//查询参数对象
const param = reactive({
  app_id: "", // 应用企业id
  app_name: "", // 应用名称
  secret: "", // 密钥
  note: "", // 备注
});
const dialogVisible = ref(false);
//查询参数对象
const ok_param = ref({
  app_id: "", // 应用企业id
  app_name: "", // 应用名称
  secret: "", // 密钥
});
// 重置功能
const reset = () => {
  param.app_id = "";
  param.app_name = "";
  param.secret = "";
  param.note = "";
};

// 复制功能
const { toClipboard } = useClipboard();
const copyButton = async (type) => {
  try {
    let text = "";
    if (type == "secret") {
      text = ok_param.value.secret;
    } else {
      text =
        "应用id：" +
        ok_param.value.app_id +
        ";\n" +
        "应用名称：" +
        ok_param.value.app_name +
        ";\n" +
        "密钥：" +
        ok_param.value.secret;
    }
    await toClipboard(text);
    ElMessage({
      grouping: true,
      showClose: true,
      message: "复制成功",
      type: "success",
    });
  } catch (e) {
    console.error(e);
  }
};

const addApp = async () => {
  let res = await add_app({
    app_id: param.app_id, // 应用企业id
    app_name: param.app_name, // 应用名称
    note: param.note, // 备注
  });
  if (res.code == 200) {
    ok_param.value = res.data;
    dialogVisible.value = true;
    reset();
  }
};
</script>

<style scoped>
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  width: 80%;

  .title {
    /* flex: 1; */
    width: 100px;
    text-align: right;
  }

  .txt {
    width: 400px;
    /* flex: 8; */
  }
}

.dialog {
  margin: 20px;
  border: dashed 1px #808080;
  border-radius: 10px;
  padding: 10px;
}

.dialog_text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;

  .dialog_title {
    text-align: left;
  }

  .dialog_txt {
    flex: 3;
  }
}
</style>
